<template>
  <div>
    <div class="track-colors">
      <div class="header">
        <label>{{ $t('trackColors.title') }}:</label>
        <div>
          <el-popover placement="left-start" trigger="click" width="450" popperClass="colors-export-popper" v-model="viewExportColors">
            <div class="header-content">
              <skif-search class="search" v-model="searchQuery" :placeholder="$t('search')" @searching="searchCompanies" />
            </div>
            <!-- TODO: add filter (search) in table -->
            <el-table :data="filtredCompanies" height="450" width="450" @selection-change="handleSelectionChange" size="small" class="export-colors-table" :header-row-class-name="setHeaderRowClass">
              <el-table-column type="selection" width="25" align="center"></el-table-column>
              <el-table-column prop="name">
                <template slot="header">
                  <span class="mr-4">{{ $t('trackColors.selected') + selectedCompanies.length }}</span></template
                >
              </el-table-column>
            </el-table>
            <div class="accept-btn">
              <button @click="exportColors">{{ $t('monitoring.accept') }}</button>
            </div>
            <div class="export-link" slot="reference">
              <!-- @click="exportColors" -->
              <span> {{ $t('trackColors.export') }}</span>
              <i class="el-icon-arrow-down ml-2"></i>
            </div>
          </el-popover>
        </div>
      </div>
      <div v-for="(color, index) in trackColors" :key="index" class="color-row" ref="colorRow">
        <span>{{ $t('trackColors.to') }}:</span>
        <el-input v-model="color.max" :placeholder="color.max === null ? '∞' : ''" class="value-input" :disabled="color.max === null" :type="'number'"></el-input>
        <el-color-picker v-model="color.hex_color" class="color-picker-tracks"></el-color-picker>
        <el-tooltip v-if="color.max === null && isAddDisabled" placement="bottom" :openDelay="300" :visible-arrow="false" effect="customized">
          <template #content>
            <div class="custom-tooltips">
              <span> {{ $t('trackColors.limits') }} </span>
            </div></template
          >
          <div class="add-icon" @click="addColor">
            <AddIcon :isAddDisabled="isAddDisabled" />
          </div>
        </el-tooltip>
        <div v-else-if="color.max === null" class="add-icon" @click="addColor">
          <AddIcon :isAddDisabled="isAddDisabled" />
        </div>
        <div v-else class="delete-icon" :class="{ 'is-disabled': isAddDisabled }" @click="removeColor(index)">
          <DeleteIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, onMounted } from 'vue'
import { useStore } from '@/store/store.js'
import { createVuexHelpers } from 'vue2-helpers'
import DeleteIcon from './icons/DeleteIcon.vue'
import AddIcon from './icons/AddIcon.vue'
import { useI18n } from '@/hooks/useI18n'

const $t = useI18n()
const Store = useStore()
const { useActions, useGetters } = createVuexHelpers()
const { SET_TRACKS_COLORS, GET_TRACKS_COLORS, EXPORT_COLORS } = useActions('company', ['SET_TRACKS_COLORS', 'GET_TRACKS_COLORS', 'EXPORT_COLORS'])
const { me } = useGetters('login', ['me'])
const { trackColors } = useGetters('company', ['trackColors'])

const viewExportColors = ref(false)
const selectedCompanies = ref([])
const searchQuery = ref('')

const companies = computed(() => Store.state.login.me.companies)
const filtredCompanies = computed(() => {
  return companies.value.map(company => ({ id: company.id, name: company.name })).filter(company => company.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
})
const isAddDisabled = computed(() => trackColors.value.length >= 7)

const searchCompanies = val => {
  searchQuery.value = val
}
const handleSelectionChange = selection => {
  selectedCompanies.value = selection
}
const setHeaderRowClass = () => 'custom-header-row'
const initialTrackColors = ref(null)
/**
 * Adds a new color to the trackColors array.
 * If the maximum number of colors is reached(7 colors), the function does nothing.
 */
const addColor = () => {
  // Check if the maximum number of colors is reached
  if (isAddDisabled.value) return

  // Add a new color to the trackColors array
  trackColors.value.splice(trackColors.value.length - 1, 0, { max: '', hex_color: '#858585' })
}

const removeColor = index => {
  if (trackColors.value[index].isDisabled || trackColors.value.length <= 1) return
  trackColors.value.splice(index, 1)
}
/** Validate the colors and return - color.max is the speed limit for each color */
const validateColors = () => trackColors.value.filter(color => color.max !== '')

/**
 * Set tracks colors for the active company.
 * It validates the colors and sends a request to the server.
 * @return {Promise<void>} - A promise that resolves when the colors are set successfully.
 * @throws {Error} - If there's an error while setting the colors.
 */
const setTracksColors = async () => {
  try {
    // we check if the colors changed first !
    const colorsChanged = JSON.stringify(trackColors.value) !== JSON.stringify(initialTrackColors.value)
    if (!colorsChanged) return

    // Validate the colors
    const validatedColors = validateColors()

    // Get the id of the active company
    const companyId = me.value.active_company.id

    // Send a request to the server to set the colors
    await SET_TRACKS_COLORS({ colors: validatedColors, companyId })
  } catch (error) {
    console.error('Failed to set track colors:', error)
  }
}
/**
 *  export colors asynchronously for each company selected.
 */
const exportColors = async () => {
  try {
    const data = {
      colors: validateColors(),
      selectedCompanies: selectedCompanies.value
    }
    await EXPORT_COLORS(data)
    viewExportColors.value = false
  } catch (error) {
    console.error('Failed to export colors:', error)
  }
}

onMounted(() => {
  GET_TRACKS_COLORS(me.value.active_company.id).then(() => {
    initialTrackColors.value = JSON.parse(JSON.stringify(trackColors.value))
  })
})
onBeforeUnmount(() => {
  setTracksColors()
})
</script>

<style scoped lang="scss">
@import './TrackColors.scss';
</style>
