import { render, staticRenderFns } from "./GroupDots.vue?vue&type=template&id=bd5961bc&scoped=true"
import script from "./GroupDots.vue?vue&type=script&setup=true&lang=js"
export * from "./GroupDots.vue?vue&type=script&setup=true&lang=js"
import style0 from "./GroupDots.vue?vue&type=style&index=0&id=bd5961bc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd5961bc",
  null
  
)

export default component.exports