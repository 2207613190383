var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex items-center measure-units border border-lightGrey-400"},_vm._l((_setup.measureUnits),function(unit){return _c('div',{key:unit.value,class:[
      'measure-units__item',
      'flex',
      'items-center',
      'justify-center',
      'cursor-pointer',
      'font-semibold',
      'text-base',
      _setup.isActive === unit.value
        ? 'bg-blue-200 text-white'
        : 'bg-lightGrey-100 text-black-100'
    ],on:{"click":function($event){return _setup.changeMeasure(unit.value)}}},[_vm._v(" "+_vm._s(unit.text)),(unit.hasSup)?_c('sup',{staticClass:"text-sizeTen"},[_vm._v("2")]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }