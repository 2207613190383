<template>
  <el-switch
    v-model="switchValue"
    :disabled="disabled"
    active-color="#3A7CCA"
    inactive-color="#859CB9"
    @change="$emit('onChange', switchValue)"
    @click.stop
  />
</template>
<script setup>
import { ref, watch, onBeforeMount } from 'vue'

const switchValue = ref(false)

const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

watch(
  () => props.value,
  (val) => {
    switchValue.value = val
  }
)

onBeforeMount(() => {
  switchValue.value = props.value
})
</script>
